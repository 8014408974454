import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginPeticion } from 'src/app/models/login/login-peticion';
import { PreLoginPeticion } from 'src/app/models/pre-login/PreLogin-peticion';
import { AutenticadorService } from 'src/app/services/autenticador/autenticador.service';
import { CustomValidators } from '../../utiles/custom-validators';
import { LoggerService } from 'src/app/services/logger.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [AutenticadorService]
})
export class LoginComponent implements OnInit {
  hide = true;
  formGroup: FormGroup;
  loading = false;
  errorLogin: boolean;
  errorMensage = '';
  preLoginsLS;
  tpoDocUsr;
  formaDocUsr;
  tpoDocEmp;
  public esPortalComercio: boolean = false;
  public estipoRut: boolean = false;
  //public rutinvalido: boolean = false;

  loginPeticion = new LoginPeticion();
  preLoginPeticion = new PreLoginPeticion();
  versionPortal: any;

  constructor(private router: Router, private autenticatorService: AutenticadorService, @Inject('env') private environment: any, private logger: LoggerService) { 
    this.preLoginsLS = JSON.parse(sessionStorage.getItem('preLogin'));
    this.logger.log("login preloginLS", this.preLoginsLS);
  }

  ngOnInit() {
    this.errorLogin = false;
    this.logger.log("login: ", this.preLoginsLS);
    sessionStorage.setItem('login', 'login');

    if (this.preLoginsLS.tpoDocUsr == 'USERNAME') {
      this.tpoDocUsr = 'Usuario';
    } else {
      if (this.preLoginsLS.tpoDocUsr == 'RUT') {
        this.tpoDocUsr = 'Rut Usuario';
      } else {
        this.tpoDocUsr = 'Email Usuario';
      }
    }
    this.formaDocUsr = this.preLoginsLS.tpoDocUsr;

    this.formGroup = new FormGroup({
      rutEmpresa: new FormControl('', Validators.compose([CustomValidators.rutValidator, Validators.required])),
      usuario: this.formDocUsr(),
      password: new FormControl('', Validators.required)
    });
    this.formGroup.controls['rutEmpresa'].disable();

    if (this.preLoginsLS.codTpoPortal == 'COMERCIO') {// habilita combo Empresa si es Portal Comercio
      this.esPortalComercio = true;
      this.formGroup.controls['rutEmpresa'].enable();
      if (this.preLoginsLS.tpoDocEmp == 'RUT') {
        this.tpoDocEmp = 'Rut Empresa';
      }
    }

    if (this.preLoginsLS.tpoDocEmp == 'RUT') {
      this.estipoRut = true;
    }
  }
  formDocUsr() {
    if (this.formaDocUsr == 'RUT') {
      return new FormControl('', Validators.compose([CustomValidators.rutValidator, Validators.required]));
    } else {
      if (this.formaDocUsr == 'USERNAME') {
        return new FormControl('', Validators.required);
      } else {  // Email
        return new FormControl('', Validators.compose([Validators.email, Validators.required]));
      }
    }
  }
  
  doLogin(body: LoginPeticion) {
    this.autenticatorService.doLogin(body).subscribe(data => {
      if (data.codret == '0') {
        this.logger.log("userPerfil: ", data);
        sessionStorage.setItem('userPerfil', JSON.stringify(data));
        if (data['perfil']['idStdPrf'] != '1') {
          this.router.navigate(['/mantencion']);
        } else {
          sessionStorage.setItem('docUsuario', this.formGroup.get('usuario').value);
          sessionStorage.setItem('idPerfil', data['perfil']['idPerfil']);
          if (data['usuario']['codEstado'] == 'CAMBIOPASS') {
            this.router.navigate(['/cambiopass']);
          } else {
            sessionStorage.setItem('currentUser', data['usuario']);
            sessionStorage.setItem('tokenSession', data['tokenSession']);
            sessionStorage.setItem('minExpSession', data['perfil']['polMinExpSesion']);
            let urlMenu = data.perfil.modulos[0].url;
            this.router.navigate(['/home/' + urlMenu]);
          }
        }
      } else {
        this.errorLogin = true;
        this.errorMensage = "" + data.descret;
        this.loading = false;
      }
    }, error => {
      this.errorLogin = true;
      this.errorMensage = error.statusText;
      this.loading = false;
      this.logger.log("error", error);
    });
  }

  login() {
    this.loading = true;
    if (this.formGroup.get('rutEmpresa').value) {
      this.loginPeticion.docEmpresa = this.formGroup.get('rutEmpresa').value;
    } else {
      this.loginPeticion.docEmpresa = this.environment.docEmpresa;
    }
    this.loginPeticion.docUsuario = this.formGroup.get('usuario').value;
    this.loginPeticion.claveUsuario = this.formGroup.get('password').value;
    this.loginPeticion.codCliente = this.environment.codCliente;
    this.loginPeticion.codTpoPortal = this.preLoginsLS.codTpoPortal;

    let params: PreLoginPeticion = new PreLoginPeticion();
    params.codCliente = this.environment.codCliente;
    params.urlPortal = this.environment.urlPortal;

    this.autenticatorService.preLogin(params).subscribe(data => {
      if (data.codret == '0') {
        sessionStorage.setItem('preLogin', JSON.stringify(data));
        if (data.estadoPortal == 'SUSPENDIDO') {
          this.router.navigate(['/mantencion']);
        } else {
          sessionStorage.setItem('tokenSession', data.tokenSession);
          this.doLogin(this.loginPeticion);
        }
      } else {
        sessionStorage.setItem('preLoginError', JSON.stringify(data));
        this.router.navigate(['/mantencion']);
      }
    }, error => {
      sessionStorage.setItem('preLoginError', JSON.stringify(error));
      this.router.navigate(['/mantencion']);
    });

  }

}
