import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { LoggerService } from './logger.service';

@Injectable()
export class ValidaloginService implements CanActivate {

  constructor(private router: Router, private logger: LoggerService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (sessionStorage.getItem('preLogin')) {
      this.logger.log("sesion activa");
      return true;
    } else {
      this.logger.log("sesion NO activa");
      this.router.navigate(['']);
      return false;
    }

  }

}