import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  error(...args: any[]): void {
    if(!environment.production){
      if(args.length === 1 && Array.isArray(args[0])){
        console.error(...args[0]);
      }else{
        console.error(...args);
      }
    }
  }

  log(...args: any[]): void {
    if(!environment.production){
      if(args.length === 1 && Array.isArray(args[0])){
        console.log(...args[0]);  
      }else{
        console.log(...args);
      }
    }
  }

}