import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { RecuperarPassCorreoPeticion } from 'src/app/models/recuperar-pass/recuperarPassCorreo-peticion';
import { Router } from '@angular/router';
import { RecuperarPassService } from 'src/app/services/recuperarPass/recuperar-pass.service';
import { CustomValidators } from 'src/app/components/utiles/custom-validators';
import { LoggerService } from 'src/app/services/logger.service';

@Component({
  selector: 'app-recuperar-pass-correo',
  templateUrl: './recuperar-pass-correo.component.html',
  styleUrls: ['./recuperar-pass-correo.component.css'],
  providers: [RecuperarPassService]
})
export class RecuperarPassCorreoComponent implements OnInit {
  FormGroup: FormGroup;
  loading: boolean = false;
  errorValidaCorreo: boolean;
  errorMensage: string;
  recuperarPassCorreoPeticion = new RecuperarPassCorreoPeticion();
  preLoginsLS;
  tpoDocUsr;
  formaDocUsr;
  tpoDocEmp;
  esPortalComercio: boolean = false;
  public estipoRut: boolean = false;

  constructor(
    private router: Router,
    private recuperarPassService: RecuperarPassService,
    @Inject('env') private environment: any,
    private logger: LoggerService
  ) { }

  ngOnInit() {
    
    this.preLoginsLS = JSON.parse(sessionStorage.getItem('preLogin'));
    this.formaDocUsr = this.preLoginsLS.tpoDocUsr;

    if (this.preLoginsLS.tpoDocUsr == "USERNAME") {
      this.tpoDocUsr = "Usuario";
    } else {
      if (this.preLoginsLS.tpoDocUsr == "RUT") {
        this.tpoDocUsr = "Rut (Usuario)";
      } else {
        this.tpoDocUsr = "Correo electrónico (Usuario)";
      }
    }

    if (this.preLoginsLS.tpoDocUsr == 'RUT') {
      this.estipoRut = true;
    }

    this.FormGroup = new FormGroup({
      rutEmpresa: new FormControl('', Validators.compose([CustomValidators.rutValidator, Validators.required])),
      correo: this.formDocUsr()
    });
    this.FormGroup.controls['rutEmpresa'].disable();
    if (this.preLoginsLS.codTpoPortal == "COMERCIO") {// habilita combo Empresa si es Portal Comercio
      this.esPortalComercio = true;
      this.FormGroup.controls['rutEmpresa'].enable();
      if(this.preLoginsLS.tpoDocEmp == "RUT"){
        this.tpoDocEmp = "Rut (Empresa)";
      }
    }
  }

  formDocUsr(){
    if(this.formaDocUsr == 'RUT'){
      return new FormControl('', Validators.compose([CustomValidators.rutValidator, Validators.required]));
    }else{
      if(this.formaDocUsr == 'USERNAME'){
        return new FormControl('', Validators.required);
      }else{  // Email
        return new FormControl('', Validators.compose([Validators.email, Validators.required]));
      }
    }
  }

  doRecuperarPassCorreo(body: RecuperarPassCorreoPeticion) {
    this.recuperarPassService.doRecuperarCorreo(body).subscribe(data => {
      if (data.codret == '0' || data.codret == '1') {
        sessionStorage.setItem('recuperarPassCorreo', JSON.stringify(data));
        sessionStorage.setItem('correoRecuperarPass', data.correoUsuario);
        sessionStorage.setItem('rutEmpresa', this.FormGroup.get('rutEmpresa').value);
        sessionStorage.setItem('correo', this.FormGroup.get('correo').value);
        this.router.navigate(['/recuperarpassclave']);
      } else {
        this.errorValidaCorreo = true;
        this.errorMensage = "Código de error: " + data.descret;
        this.loading = false;
        this.logger.log(data);
      }
    }, error => {
      this.errorValidaCorreo = true;
      this.errorMensage = 'Servicio temporalmente no disponible.';
      this.loading = false;
      this.logger.log("Error: ", error);
    });
  }
  recuperarPassCorreo() {
    this.loading = true;

    if(this.preLoginsLS.codTpoPortal == "COMERCIO"){
      this.recuperarPassCorreoPeticion.docEmpresa = this.FormGroup.get('rutEmpresa').value;
    }else{
      this.recuperarPassCorreoPeticion.docEmpresa = this.environment.docEmpresa;
    }
    this.recuperarPassCorreoPeticion.correoUsuario = this.FormGroup.get('correo').value;
    //this.recuperarPassCorreoPeticion.codCliente = this.environment.codCliente;
    //this.recuperarPassCorreoPeticion.codTpoPortal = this.preLoginsLS.codTpoPortal;

    this.doRecuperarPassCorreo(this.recuperarPassCorreoPeticion);
  }

}
