import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { LoggerService } from './logger.service';

@Injectable()
export class ValidaModuloService implements CanActivate {
  userPerfil: { perfil: { modulos: any; }; };
  lstModulos: any[];
  constructor(private router: Router, private logger: LoggerService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    this.userPerfil = JSON.parse(sessionStorage.getItem('userPerfil'));
    //this.logger.log(this.userPerfil);
    this.lstModulos = this.userPerfil.perfil.modulos;
    for (let index = 0; index < this.lstModulos.length; index++) {
      const modulo = this.lstModulos[index];
      if (this.validaModulo(modulo, route.url)) {
        return true;
      }
    }
    this.router.navigate(['']);
    return false;
  }

  validaModulo(modulo: any, url: UrlSegment[]) {
    if (url == modulo.url) {
      return true;
    } else {
      return false;
    }
  }

}