import { Injectable} from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class RemoveParamInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const uploadRegex = /upload/i; // Expresion regular que ignora mayusculas y minusculas
    if (req.method === 'POST' && !uploadRegex.test(req.url) && !req.url.includes('UplActMontoMandato')) {
      const body = { ...req.body };
  
      for (const key in body) {
        if (body[key] === -1 || body[key] === "-1" || body[key] === "-") {
            delete body[key];
        }
      }
  
      const clonedRequest = req.clone({ body });
  
      return next.handle(clonedRequest);
    }
  
    return next.handle(req);
  }
}