import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RecuperarPassClavePeticion } from 'src/app/models/recuperar-pass/recuperarPassClave-peticion';
import { RecuperarPassService } from 'src/app/services/recuperarPass/recuperar-pass.service';
import { MatDialog } from '@angular/material';
import { DialogBaseComponent } from 'src/app/components/dialogs/dialog-base/dialog-base.component';
import { LoggerService } from 'src/app/services/logger.service';


@Component({
  selector: 'app-recuperar-pass-clave',
  templateUrl: './recuperar-pass-clave.component.html',
  styleUrls: ['./recuperar-pass-clave.component.css'],
  providers: [RecuperarPassService]
})
export class RecuperarPassClaveComponent implements OnInit {
  hideNueva = true;
  FormGroup: FormGroup;
  loading: boolean = false;
  errorValidaClave: boolean;
  errorMensage: string;
  correoRecuperarPass: string;
  recuperarPassClavePeticion = new RecuperarPassClavePeticion();
  preLoginsLS: { codTpoPortal: string; };
  rutEmpresa;
  correo;

  constructor(public dialog: MatDialog, private router: Router, private recuperarPassService: RecuperarPassService, @Inject('env') private environment: any, private logger: LoggerService) {
    this.preLoginsLS = JSON.parse(sessionStorage.getItem('preLogin'));
    this.rutEmpresa = sessionStorage.getItem('rutEmpresa');
    this.correo = sessionStorage.getItem('correo');
  }

  ngOnInit() {
    sessionStorage.removeItem('recuperarPassCorreo');
    this.FormGroup = new FormGroup({
      clave: new FormControl('', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('^[0-9]*$')]))
    });
    this.correoRecuperarPass = sessionStorage.getItem('correoRecuperarPass');
  }

  doRecuperarClave(body: RecuperarPassClavePeticion) {
    this.recuperarPassService.doRecuperarClave(body).subscribe(data => {
      if (data.codret == '0') {
        sessionStorage.setItem('recuperarPassClave', JSON.stringify(data));
        sessionStorage.setItem('recuperarPassClaveActual', this.FormGroup.get('clave').value);
        this.router.navigate(['/recuperarpasscambioclave']);
      } else {
        this.errorValidaClave = true;
        if(data.codret != '-4'){
          this.errorMensage = data.descret;
        }
        this.loading = false;
        if (data.codret == '-4') {
          this.showDialog('Error', data.descret, false);
        }
        this.logger.log(data);
      }
    }, error => {
      this.errorValidaClave = true;
      this.errorMensage = 'Servicio temporalmente no disponible.';
      this.loading = false;
      this.logger.log("Error: ", error);
    });
  }

  recuperarPassClave() {
    this.loading = true;
    if (this.preLoginsLS.codTpoPortal == "COMERCIO") {
      this.recuperarPassClavePeticion.docEmpresa = this.rutEmpresa;
    } else {
      this.recuperarPassClavePeticion.docEmpresa = this.environment.docEmpresa;
    }
    //this.recuperarPassClavePeticion.codCliente = this.environment.codCliente;
    //this.recuperarPassClavePeticion.codTpoPortal = this.preLoginsLS.codTpoPortal;

    this.recuperarPassClavePeticion.claveUsuario = this.FormGroup.get('clave').value;
    this.recuperarPassClavePeticion.correoUsuario = this.correo;
    this.recuperarPassClavePeticion.tipoEnvio = 'RECUPERARCLAVE';

    this.doRecuperarClave(this.recuperarPassClavePeticion);

  }

  showDialog(pTitle: string, pContent: string, pShowBtnCancel: boolean) {
    const dialogRef = this.dialog.open(DialogBaseComponent, {
      data: { title: pTitle, content: pContent, showBtnCancel: pShowBtnCancel, btnCancelar: 'Cancelar', btnAceptar: 'Aceptar' },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/inicio']);
      }
    });
  }
  
}
